import React from "react";
import Form from "../components/form/Form";
import {
  formData,
  infoText,
  btnText,
  confirmationText,
  errorText
} from "../constants/formData";

import "../styles/kontakt.scss";

const Kontakt = () => {
  const recaptchaSitekey = process.env.GATSBY_SITE_RECAPTCHA_KEY;
  return (
    <div className="page kontakt">

      <h1>Schick uns Deine Anfrage!</h1>
      <Form
        handleIsOpen={false}
        form={formData}
        infoText={infoText}
        btnText={btnText}
        confirmationText={confirmationText}
        errorText={errorText}
        recaptchaSitekey={recaptchaSitekey}
      />
    </div>
  );
};

export default Kontakt;
