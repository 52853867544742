import React, { useState, useRef } from "react";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import Input from "../input/Input";
import Button from "../button/Button";
import "./form.styles.scss";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { BiErrorCircle } from "react-icons/bi";

const Form = ({
  isOpen,
  handleIsOpen,
  form,
  infoText,
  btnText,
  confirmationText = "Submitted",
  errorText = "error",
  recaptchaSitekey
}) => {
  const {
    register,
    handleSubmit,
    reset,
    // watch,
    formState: { errors }
  } = useForm();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const recaptchaRef = useRef();

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    setIsError(false);

    //TODO For testing
    // setIsLoading(false)
    // setIsSubmitted(true)
    // reset()

    const recaptchaToken = await recaptchaRef.current.executeAsync();

    const formData = {
      ...data,
      "g-recaptcha-response": recaptchaToken
    };

    //TODO enable for emailjs
    emailjs
      .send(
        process.env.GATSBY_SERVICE_ID,
        process.env.GATSBY_EMAILJS_TEMPLATE_ID,
        formData,
        process.env.GATSBY_EMAILJS_USER_ID
      )
      .then(
        (result) => {
          setIsLoading(false);
          setIsSubmitted(true);
          reset();
        },
        (error) => {
          setIsLoading(false);
          setIsSubmitted(true);
          setIsError(true);
          reset();
        }
      );

  });

  return (
    <form>
      {/* <button type="button" className="close__btn">
        <AiOutlineClose onClick={() => { handleIsOpen(); setIsSubmitted(false); setIsLoading(false); }} />
      </button> */}
      {!isSubmitted ? (
        <>
          {infoText && (
            <span
              className="form__infoText"
              dangerouslySetInnerHTML={{ __html: infoText }}
            ></span>
          )}

          {form.map((input, index) => (
            <Input
              key={index}
              type={input.type}
              label={input.label}
              name={input.name}
              register={register}
              placeholder={input.placeholder}
              textarea={input.textarea}
              validation={input.validation}
              errors={errors}
            />
          ))}
          <div className="form__btn">
            <Button isLoading={isLoading} onClick={onSubmit} color="secondary">
              {btnText}
            </Button>
          </div>
        </>
      ) : (
        <>
          {!isError ? (
            <span className="form__confirmationText">
              <p>{confirmationText}</p>
              <AiOutlineCheckCircle size={50} color="green" />
            </span>
          ) : (
            <span className="form__errorText">
              <p>{errorText}</p>
              <BiErrorCircle size={50} color="red" />
            </span>
          )}
        </>
      )}
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={recaptchaSitekey}
      />
    </form>
  );
};

export default Form;
