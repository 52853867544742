import React from "react";
import "./input.styles.scss";

const Input = ({
  label = "",
  type = "text",
  name = "name",
  placeholder = "placeholder",
  register = () => null,
  validation = null,
  errors = null
}) => {
  const renderInput = () => {
    switch (type) {
      case "checkbox":
        return (
          <>
            <div className="input-field checkbox">
              <input
                type={type}
                name={name}
                placeholder={placeholder}
                {...register(name, validation)}
              />
            </div>
            {label && (
              <p className="checkbox-text">
                {label}
                {validation?.required?.value && "*"}
              </p>
            )}
          </>
        );
      case "textarea":
        return (
          <>
            <label className="input-label">
              {label}
              {validation?.required?.value && " *"}
            </label>
            <div className="input-field textarea-field">
              <textarea
                rows={20}
                cols={50}
                name={name}
                placeholder={placeholder}
                {...register(name, validation)}
              />
            </div>
          </>
        );
      default:
        return (
          <>
            <label className="input-label">
              {label}
              {validation?.required?.value && " *"}
            </label>
            <div className="input-field">
              <input
                type={type}
                name={name}
                placeholder={placeholder}
                {...register(name, validation)}
              />
            </div>
          </>
        );
    }
  };
  return (
    <div className="input">
      {renderInput()}
      {errors?.[name] && (
        <div>
          <strong role="alert" className="input-validation-error">
            {errors[name].message}
          </strong>
        </div>
      )}
    </div>
  );
};

export default Input;
